<script>
//import Multiselect from "vue-multiselect";
export default {
  props: {
    empresa: { type: Object, required: true },
    ibge: { type: Array }
  },
  //components: { Multiselect },
  data() {
    return {
      titleBody: 'Incluir Situação OS',

      cidades: {
        cod: null,
        nome: '',
        estado: null
      },
      estados: [],
      estados2: [],
      situacao:{
        codigo: null,
        sigla: null,
        descricao:null

      }
    }
  },
  created() { },
  mounted() {
    this.$emit('newTitle', this.titleBody);
    //console.log(this.ibge);
    this.convert_citys();
  },

  methods: {

    adicionarSemDuplicatas(novoObjeto) {
      if (!this.estados.some(objeto => objeto.nome === novoObjeto.nome)) {
        this.estados.push(novoObjeto);
      }
    },

    convert_citys() {



      this.ibge.forEach((cidade) => {

        //console.log(cidade.municipio.microrregiao.mesorregiao.UF.nome)
        //this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)
        // if (!this.estados.includes(cidade.municipio.microrregiao.mesorregiao.UF.nome)){
        //   this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF.nome)
        // }

        // if(cidade.municipio.microrregiao.mesorregiao.UF.nome
        //  != this.ibge[index+1]. municipio.microrregiao.mesorregiao.UF.nome ){
        //   this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)
        //  }
        this.adicionarSemDuplicatas(cidade.municipio.microrregiao.mesorregiao.UF);

        //this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)

        //console.log(cidade.municipio.microrregiao.mesorregiao.UF);
      });

    },





    setPost() {
      //console.log(this.cidades);
      this.$emit('doPost', this.cidades);
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <form id="form-centro-resultado" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Código</label>
                  <div class="col">
                    <input v-model="situacao.codigo" class="form-control text-right" type="text" placeholder="Codigo Cidade"
                      id="codigo_id">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Sigla</label>
                  <div class="col">
                    <input v-model="situacao.sigla" class="form-control" type="text" placeholder="Cidade"
                      id="sigla_id">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Descrição</label>
                  <div class="col">
                    <input v-model="situacao.descricao" type="text" class="form-control" placeholder="digite o estado">
                  </div>
                </div>
              </div>
              
            </div>
          </b-card-body>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPost">Gravar</button>
      </div>
    </div>
  </div>
</template>