<script>
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    title: 'Situacao OS',
    meta: [ {name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, List, Insert, Edit },
  data() {
    return {
      currentHistoricoPadrao: {},
      historicosPadroes: [],
      inserindo: false,
      editando: false,
      cidades_ibge1:[],
      
      titleBody: 'situacao OS',
      items: [
        {
        text: "Dashboard",
        href: "/",
        },
        {
        text: "Parâmetros",
        href: "/",
        active: true,
        },
        {
        text: "Situação OS",
        href: "/parametros/situacao",
        active: true,
        }
      ],
    }
  },
  computed: {
    isHide() {
      return (!this.inserindo && !this.editando);
    },
  },
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  },
  mounted() {
    this.getData();
    this.cidades_ibge();
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },

    async cidades_ibge(){
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id 
        // e  só consigo fazer o get por id ou estado ou nome ;   
        let response = await http.get('https://servicodados.ibge.gov.br/api/v1/localidades/distritos');

        if (response.status === 200) {
          this.cidades_ibge1 = response.data;
          console.log("ibge");
         // console.log(this.cidades_ibge1)

          // this.cidades_ibge1.forEach((cidade) => {

          //   console.log(cidade.municipio)
            
          // });
          this.offLoader();
        }

      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
        
          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getData() {
      this.onLoader();

      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id 
        // e  só consigo fazer o get por id ou estado ou nome ;   
        let response = await http.get('/cidade');

        if (response.status === 200) {
          this.historicosPadroes = response.data;
          //console.log(this.historicosPadroes)
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
        
          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    insert () {
      this.inserindo = !this.inserindo;
    },
    edit(historico) {
      console.log(historico);
      this.currentHistoricoPadrao = historico;
      this.editando = !this.editando;
    },
    async doPost(cidade) {
      //console.log(cidade);      
      let response = await http.post('/cidade', cidade)
        .catch((error) => {
            this.makeToast('danger', error.response);
            this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro incluído');
      }
    },
    async doPut(historico) {
      this.onLoader();
      historico.user_id = this.currentUser.id;
      let response = await http.put('/historicopadrao/'+historico.id+'?empresa_id='+this.currentEmpresa.id, historico)
        .catch((error) => {
            this.offLoader();
            this.makeToast('danger', error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro alterado');
      }
    },
    async doDelete(historico) {
      this.onLoader();
      historico.empresa_id = this.currentEmpresa.id;
      let response = await http.delete('/historicopadrao/'+historico.id+'?empresa_id='+this.currentEmpresa.id, historico)
        .catch((error) => {
            this.makeToast('danger', error.response);
            this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('warning', 'Registro excluído');
      }
    },
  },
}
</script>

<template>
<Layout>
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!-- Start Bar Buttons -->
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Situação OS</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
        <!-- End Bar Buttons -->
        <div class="card-body" v-if="!isHide">
          <Insert v-if="inserindo"
           :empresa="currentEmpresa"
           :ibge="cidades_ibge1"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
          />
          <Edit v-if="editando"
           :empresa="currentEmpresa"
           :oldHistoricoPadrao="currentHistoricoPadrao"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
        </div>
        <List v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listHistoricosPadroes="historicosPadroes"
         :hide="isHide"
         @newTitle="changeHomeTitle"
         @edit="edit"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div>
</Layout>
</template>